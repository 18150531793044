import axios from "axios";
var omniApiUrlPrefix = process.env.VUE_APP_OMNI_BASE_URL;

export async function sendFeedbackEmail(data) {
  return axios
    .post(omniApiUrlPrefix + "/api/shared/sendFeedbackEmail", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function getTemplateUsage(payload) {
  return axios
    .get(omniApiUrlPrefix + "/api/report/getTemplateUsage", {
      params: {
        startDate: payload.startDate,
        endDate: payload.endDate,
        providerId: payload.providerId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getHoldPayReport() {
  return axios
    .get(omniApiUrlPrefix + "/api/report/getHoldPayReport")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
