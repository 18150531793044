<template>
  <v-btn
    small
    style="background-color: #1c344b"
    v-on:click="submit"
    dark
    class="mt-5"
  >
    <icon icon="cloud-download-alt" class="mr-3" />Generate Report</v-btn
  >
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  methods: {
    ...mapActions("reportsModule", ["getHoldPayReport"]),
    ...mapMutations("reportsModule", ["clearReportState"]),
    submit: function () {
      this.getHoldPayReport();
    },
  },
};
</script>

<style></style>
