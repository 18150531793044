import Home from "../components/Home/home";
import TemplateUsageReport from "../components/Reports/TemplateUsage/template-usage-report.vue";
import HoldPayReport from "../components/Reports/HoldPay/hold-pay-report.vue";
import Callback from "../components/Security/callback";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: "home",
    title: "Home",
    component: Home,
    onNavBar: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Template Usage",
    path: "/templateUsageReport",
    icon: "file-contract",
    title: "Template Usage",
    component: TemplateUsageReport,
    onNavBar: true,
    onTileGrid: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "HoldPay",
    path: "/holdPayReport",
    icon: "file-invoice-dollar",
    title: "Hold Pay",
    component: HoldPayReport,
    onNavBar: true,
    onTileGrid: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
  },
];
