<template>
  <v-container>
    <v-row>
      <v-col cols="4"
        ><form-field
          :field="startDate"
          :readonly="false"
          :validations="$v.values.startDate"
        ></form-field>
      </v-col>
      <v-col cols="4">
        <form-field
          :field="endDate"
          :readonly="false"
          :validations="$v.values.endDate"
        ></form-field>
      </v-col>
      <v-col cols="4">
        <v-btn small outlined @click="clearFields" class="mt-5 mr-5"
          >Clear Fields</v-btn
        >
        <v-btn
          small
          color="primary"
          :disabled="!isValid"
          :dark="Boolean(isValid)"
          @click="submit"
          class="mt-5"
          >Generate Report</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FieldTypes } from "../../../objs/Shared/FieldTypes";
import FormField from "../../../objs/Shared/FormField";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  required,
  alphaNumeric,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
export default {
  computed: {
    ...mapState("reportsModule", {
      values: "reportParams",
    }),
    startDate: function () {
      return new FormField({
        tier: 1,
        moduleName: "reportsModule",
        objName: "reportParams",
        propName: "startDate",
        label: "Start Date",
        componentName: FieldTypes.DATE_PICKER,
      });
    },
    endDate: function () {
      return new FormField({
        tier: 1,
        moduleName: "reportsModule",
        objName: "reportParams",
        propName: "endDate",
        label: "End Date",
        componentName: FieldTypes.DATE_PICKER,
      });
    },
    providerInfo: function () {
      return new FormField({
        tier: 1,
        moduleName: "reportsModule",
        objName: "reportParams",
        propName: "providerId",
        label: "Provider ID",
        componentName: FieldTypes.TEXT_FIELD,
      });
    },
    isValid: function () {
      if (!this.$v.values.$invalid) return true;
      else return false;
    },
  },
  methods: {
    ...mapActions("reportsModule", ["getTemplateUsage"]),
    ...mapMutations("reportsModule", ["clearReportState"]),
    submit: function () {
      this.getTemplateUsage();
    },
    clearFields: function () {
      this.clearReportState();
    },
  },
  validations() {
    var today = new Date();
    var tomorrow = new Date().setDate(today.getDate() + 1);
    var sixMonthsAgo = new Date().setMonth(today.getMonth() - 6);
    let validations = {
      startDate: {
        required: required,
        minValue: minValue(new Date(sixMonthsAgo)),
        maxValue: maxValue(new Date(tomorrow)),
      },

      endDate: { required, maxValue: maxValue(new Date(tomorrow)) },
      //  providerId: { alphaNumeric },
    };

    return {
      values: validations,
    };
  },
};
</script>

<style></style>
