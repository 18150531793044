<template>
  <v-data-table
    v-if="show"
    :headers="headers"
    :items="unitsReport"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ tableTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
import * as moment from "moment";
export default {
  computed: {
    ...mapState("reportsModule", ["unitsReport", "reportParams"]),
    show: function () {
      return this.unitsReport.length > 0;
    },
    tableTitle: function () {
      return (
        "Template Usage from " +
        moment(this.reportParams.startDate).format("MMMM Do YYYY") +
        " through " +
        moment(this.reportParams.endDate).format("MMMM Do YYYY")
      );
    },

    headers() {
      return [
        {
          text: "Service Coordinator",
          align: "start",
          value: "name",
        },
        { text: "Provider ID", value: "providerID" },
        { text: "Billable Units", value: "units" },
      ];
    },
  },
};
</script>
