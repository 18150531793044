export default class TemplateReportParameters {
  providerId?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  public constructor(
    params: TemplateReportParameters = {} as TemplateReportParameters
  ) {
    let { providerId = "0", startDate = null, endDate = null } = params;
    this.providerId = providerId;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
