<template>
  <tile-grid></tile-grid>
</template>

<script>
import TileGrid from "./tile-grid.vue";

export default {
  components: { TileGrid },
};
</script>
