<template>
  <v-card>
    <v-card-title>Hold Pay Report</v-card-title>
    <v-card-subtitle>
      <generate-report-button></generate-report-button>
    </v-card-subtitle>
  </v-card>
</template>
<script>
import GenerateReportButton from "./generate-report-button";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    GenerateReportButton,
  },
  computed: { ...mapState("reportsModule", ["holdPayReport"]) },
  methods: { ...mapMutations("reportsModule", ["clearReportState"]) },
  beforeDestroy() {
    this.clearReportState();
  },
};
</script>
