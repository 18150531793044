<template>
  <v-app id="app">
    <toolbar></toolbar>
    <nav-drawer></nav-drawer>

    <v-main>
      <v-container fluid>
        <v-layout align-center justify-left>
          <div class="container">
            <snackbar></snackbar>
            <loader></loader>
            <router-view v-show="showRouterView"></router-view>
            <help style="position:fixed; bottom:40px; right: 25px;"></help>
          </div>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import Toolbar from "./components/AppBar/toolbar";
  import NavDrawer from "./components/NavigationBar/nav-drawer";
  import Snackbar from "./components/Shared/snackbar";
  import Help from "./components/Shared/help";

  import Loader from "./components/Shared/loader";
  export default {
    name: "app",
    components: {
      toolbar: Toolbar,
      navDrawer: NavDrawer,
      snackbar: Snackbar,
      loader: Loader,
      help: Help
    },
    computed: {
      showRouterView: function ()
      {
        var requiresAuth = this.$router.currentRoute.meta.requiresAuth;
        return (!requiresAuth) || (this.$store.state.securityModule.requiresAuth && this.$store.state.securityModule.isAuthenticated);
      }
    }
  };
</script>
