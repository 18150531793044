import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

Vue.config.devtools = true;

import uxModule from "./Modules/uxModule";
import formFieldsModule from "./Modules/formFieldsModule";
import sharedModule from "./Modules/sharedModule";
import securityModule from "./Modules/securityModule";
import reportsModule from "./Modules/reportsModule";
const state = {};

const mutations = {};

const actions = {};

const store = new Vuex.Store({
  modules: {
    uxModule: uxModule,
    formFieldsModule: formFieldsModule,
    sharedModule: sharedModule,
    securityModule: securityModule,
    reportsModule: reportsModule,
  },

  state,
  mutations,
  actions
});

export default store;
