<template>
  <transition appear name="slide-fade" mode="out-in">
    <v-container class="mt-12" row fluid>
      <tile-item
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        class="mr-10"
      ></tile-item></v-container
  ></transition>
</template>

<script>
import TileItem from "./tile-item";
import { routes } from "../../router/routes.js";
export default {
  components: {
    TileItem,
  },
  data() {
    return {
      routes,
    };
  },
  computed: {
    items: function () {
      return this.routes.filter((r) => r.onTileGrid == true);
    },
  },
};
</script>
