<template>
  <v-card>
    <v-card-title>Template Usage Report</v-card-title>
    <v-card-subtitle>
      <input-form></input-form>
    </v-card-subtitle>
    <v-card-text>
      <unit-data-table class="my-12"></unit-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import UnitDataTable from "./unit-data-table";
import InputForm from "./input-form";
import { mapMutations } from "vuex";
export default {
  components: {
    InputForm,
    UnitDataTable,
  },
  methods: { ...mapMutations("reportsModule", ["clearReportState"]) },
  beforeDestroy() {
    this.clearReportState();
  },
};
</script>
