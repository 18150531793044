import * as api from "../Services/api.js";
import TemplateReportParameters from "../../objs/TemplateReportParameters";

const state = {
  reportParams: new TemplateReportParameters(),
  unitsReport: [],
  holdPayReport: [],
};

const mutations = {
  clearReportState: (state) => {
    state.reportParams = new TemplateReportParameters();
    state.unitsReport = [];
    state.holdPayReport = [];
  },
  setUnitsReport: (state, payload) => {
    state.unitsReport = payload;
  },
  setHoldPayReport: (state, payload) => {
    state.holdPayReport = payload;
  },
};

const actions = {
  async getTemplateUsage({ commit, state }) {
    commit("uxModule/setShowLoader", true, {
      root: true,
    });

    return api.getTemplateUsage(state.reportParams).then((response) => {
      commit("setUnitsReport", response);

      commit("uxModule/setShowLoader", false, {
        root: true,
      });
    });
  },

  async getHoldPayReport({ commit, state }) {
    commit("uxModule/setShowLoader", true, {
      root: true,
    });
    return api.getHoldPayReport().then((response) => {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(response);
      hiddenElement.target = "_blank";
      hiddenElement.download = "HoldPayReport.csv";
      hiddenElement.click();
      commit("uxModule/setShowLoader", false, {
        root: true,
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
